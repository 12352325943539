import axios from 'axios'

const token = localStorage.getItem("token");
let ApiUrl = '';
if (window.location.origin.includes('localhost')) {
  ApiUrl = 'http://localhost:8000/';
} else {
  ApiUrl = 'https://endpoints.theride.rw/';
}
const instance = axios.create({
  baseURL: ApiUrl,
  headers: {
    'Authorization': 'Bearer ' + token // set the token here
  }
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if(error.response.status == 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.href = '/login'
    }
    return Promise.reject(error);
  }
);

export default instance